<template>
  <div class="Banner">
    <el-container>
        <el-header class="Header">
            <el-row justify="space-between">
                <el-col :span="2">
                    <div class="Index" @click="ToIndex()">首页</div>
                </el-col>
                <el-col :span="2">
                    <div class="Active" @click="ToEvents">活动</div>
                </el-col>
                <!-- <el-col :span="2">
                    <div class="Square">广场</div>
                </el-col> -->
                <el-col :span="2">
                    <div class="QA" @click="ToMainQA">Q&A</div>
                </el-col>
                <el-col :span="4"><div class=""></div></el-col>
            </el-row>
        </el-header>
        <el-main class="Content">
            <div class="BodyTab">

            </div>
            <div class="BannerBody">
                <div class="BannerBodyLft">
                    <div class="Item" v-for="(item,index) in bloglist" :key="index" @click="BrowserBlog(item.id)">
                        <div class="UpItem">
                            <div class="Title">{{item.title}}</div>
                            <div class="Time">{{item.createTime}}</div>
                        </div>
                        <div class="MiddleItem">
                            <div class="Tags" v-for="(itemtag,index) in item.keywords" :key="index">{{itemtag}}</div>
                        </div>
                        <div class="DownItem">
                            
                            <div v-if="!item.hasLike">
                                <div class="ReadCount" @click.stop="_isLike(item.id)">
                                    <i class="iconfont icon-dianzan" ></i>
                                    <span class="count">{{item.likeCount}}</span>赞
                                </div>
                            </div>
                            <div v-else>
                                <div class="ReadCount" @click.stop="_unLike(item.id)">
                                    <i class="iconfont icon-dianzan1" ></i>
                                    <span class="count">{{item.likeCount}}</span>赞
                                </div>
                            </div>
                            <div class="Comments"><i class="el-icon-chat-dot-square"></i><span class="count">{{item.commentCount}}</span></div>
                            <div class="More">
                                {{item.nickname}}
                            </div>
                        </div>
                    </div>
                    <div class="Pagniation">
                        <el-button size="mini" class="PreBtn" :disabled="preable" @click="PreItem">上一页</el-button>
                        <el-button size="mini" class="NextBtn" :disabled="nextable" @click="NextItem">下一页</el-button>
                    </div>
                </div>
                <div class="BannerBodyRight"></div>
            </div>
        </el-main>
    </el-container>
  </div>
</template>

<script>
import {isLike,unLike} from '../../api/like.js'
import '../../assets/rem.js'
import { GetBlog } from '../../api/blog'
export default {
    name:'Zone',
    data(){
        return{
            bloglist:[],
            pageNo:1,
            pageSize:10,

            preable:true,
            nextable:false,

            count:1
        }
    },
    created(){
        this.GetAllBlog()


        // this.BlogDetails()
    },
    mounted(){

    },
    computed:{
        
    },  
    methods:{
        PreItem(){
            this.count -= 1
            if(this.count == 1){
                this.preable = true
                this.nextable = false
                this.pageNo = this.count
                GetBlog(this.pageNo,this.pageSize).then(res=>{
                    this.bloglist = res.data.list
                })
            }else{
                this.nextable = false
                this.pageNo = this.count
                GetBlog(this.pageNo,this.pageSize).then(res=>{
                    this.bloglist = res.data.list
                   
                    
                })
            }
        },
        NextItem(){
            this.count += 1
            this.preable = false
            this.pageNo = this.count
            GetBlog(this.pageNo,this.pageSize).then(res=>{
                this.bloglist = res.data.list
                if(this.bloglist.length == this.pageSize){
                    this.nextable = false
                }else{
                    this.nextable = true
                }
            })
        },
        async GetAllBlog(){
            let res = await GetBlog(this.pageNo,this.pageSize)
            this.bloglist = res.data.list
            // console.log(this.bloglist)
        },
        BrowserBlog(id){
            this.$router.push({
                path:`/article/details/${id}`
            })
        },
        async _isLike(id){
            let res = await isLike(id)
            this.$message({
                message: '点赞成功',
                type: 'success'
            });
            this.GetAllBlog()
        },
        async _unLike(id){
            let res = await unLike(id)
            // console.log(id)
            this.$message({
                message: '取消点赞成功',
                type: 'success'
            });
            this.GetAllBlog()
        },
        ToIndex(){
            this.$router.push({path:'/'})
        },
        ToEvents(){
            this.$router.push({path:'/events'})
        },
        ToMainQA(){
            this.$router.push({path:'/mainqa'})
        }
    }
}
</script>

<style scoped>
.Header{
    line-height: 60px;
    width: 100%;
    box-shadow: 0px 5px 50px 0px rgb(0 0 0 / 10%);
    position: sticky;
    top: 0;
    background: #ffffff;
    padding: 0 !important;
    z-index: 20;
}
.Index , .Square , .QA ,.Active{
  height: 100%;
  font-size: 16px;
  cursor: pointer;
}
.Index:hover ,.Active:hover, .Square:hover , .QA:hover {
  background: #dddddd;
}
.Content{
    padding: 20px 0;
    width: 90%;
    margin: 0 auto;
}
.BodyTab{
    height: 50px;
    background-color: #ddd;
}
.BannerBody{
    padding: 50px;
    display: flex;
    height: auto;
}
.BannerBodyLft{
    position: relative;
    /* flex: 0 0 70%; */
    flex: 1;
    background-color: #ffffff00;
    /* background-color: #a7a7a7; */
    border-radius: 0.04rem;
}
.Item{
    padding: 20px 0px 10px 0;
    border-bottom:1px dotted #ddd;
}
.Pagniation{
    position: relative;
    display: flex;
    margin-top: 4px;
    height: 40px;
    background: white;
    align-items: center;
}
.PreBtn{
    position: absolute;
    left: 10px;
    font-size: 14px;
    /* line-height: 30px; */
}
.NextBtn{
    position: absolute;
    right: 10px;
    font-size: 14px;
    /* line-height: 30px; */
}
.Item:hover{
    background-color: rgb(238, 238, 238);
}
.UpItem{
    display: flex;
    height: 100%;
    align-items: center;
    padding: 0px 5px 10px 10px;
    color: #a3a3a3;
}
.Title{
    font-size: 18px;
    height: 20px;
    line-height: 20px;
    text-align: left;
    flex: 0 0 80%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.Title:hover{
    color: #f76400;
    cursor: pointer;
}
.Time{
    flex: 1;
    text-align: right;
    height: 20px;
    font-size: 13px;
    line-height: 20px;
    color:grey;
}
.MiddleItem{
    height: 100%;
    padding: 0px 5px 10px 10px;
    display: flex;
    align-items: center;
}
.Tags{
    height: 12px;
    width: auto;
    font-size: 12px;
    background-color: #f5f5f5;
    color: #999aaa;
    line-height: 12px;
    padding: 4px;
    margin-right: 20px;
    border-radius: 3px;
}
.DownItem{
    position: relative;
    height: 20px;
    padding: 0px 5px;
    display: flex;
    align-items: center;
}
.ReadCount ,.Comments{
    height: 20px;
    width: auto;
    padding: 0px 5px;
    line-height: 20px;
    font-size: 15px;
    color:grey;
    cursor: pointer;
}
.Comments{
    font-size: 16px;
}
.ReadCount:hover{
    color: rgb(172, 170, 170);
}
.count{
    padding: 0px 2px;
}
/* .Edit{
    position: absolute;
    right: 66px;
    height: 20px;
    font-size: 15px;
    line-height: 20px;
    padding: 0px 5px;
    cursor: pointer;
    font-weight: 600;
} */
/* .Browser{
    position: absolute;
    right: 10px;
    height: 20px;
    font-size: 15px;
    line-height: 20px;
    padding: 0px 5px;
    cursor: pointer;
    font-weight: 600;
} */
.More{
    position: absolute;
    display: flex;
    box-sizing: content-box;
    flex-direction: column;
    justify-content: right;
    right: 0;
    height: 20px;
    font-size: 14px;
    line-height: 20px;
    padding: 0px 5px;
}/* 
.el-icon-delete{
    font-size: 16px;
    padding-top: 1px;
} */
.BannerBodyRight{
    /* flex: 1; */
    /* background-color: rgb(255, 255, 255); */
}
</style>